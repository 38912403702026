class CheckAccess {
  hasAccess: boolean;

  platformURL: string | null;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static fromJson(data: any): CheckAccess {
    const instance = new CheckAccess();

    instance.hasAccess = data.HasAccess;
    instance.platformURL = data.PlatformURL;

    return instance;
  }
}

export default CheckAccess;
