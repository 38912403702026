import React from 'react';
import { EvaluationModelType } from '../../models';
import { Container, Header, LabelTile, PercentageBarItem, ProgressBarContent, ProgressBarItem, ValueTile } from './styles';
import { formatAsPercentage } from '../../utils';
const statusColor = {
    color: {
        error: '#EB5757',
        success: '#27AE60'
    },
    background: {
        error: 'rgba(235, 87, 87, .15)',
        success: 'rgba(111, 207, 151, .2)'
    }
};
const EvaluationProgressBar = ({ backgroundColor, discipline, evaluation }) => {
    if (evaluation) {
        return (React.createElement(ProgressBarContent, { "$background": backgroundColor },
            React.createElement(ProgressBarItem, { "$color": evaluation.color, now: evaluation.percentage })));
    }
    if (discipline.currentCount <= 0 && discipline.evaluations.length === 0) {
        return null;
    }
    const hasProgressBar = [
        EvaluationModelType.PERCENTAGE,
        EvaluationModelType.SCORE
    ].includes(discipline.evaluationModelType);
    if (!hasProgressBar)
        return null;
    return (React.createElement(ProgressBarContent, { "$background": backgroundColor }, discipline.evaluations.map((e, index) => (React.createElement(PercentageBarItem, { "$color": e.color, "$percentage": e.weight, key: index })))));
};
const EvaluationBarValueTile = ({ discipline, evaluation }) => {
    if (evaluation) {
        const getValueLabel = () => {
            if (discipline.evaluationModelType == EvaluationModelType.PERCENTAGE) {
                const percentage = evaluation.percentage;
                return formatAsPercentage(percentage);
            }
            const total = evaluation.currentCount;
            return total;
        };
        return React.createElement(ValueTile, null, getValueLabel());
    }
    if (discipline.currentCount <= 0 && discipline.evaluations.length === 0) {
        return React.createElement(ValueTile, { "$center": true }, 'Sem atividades');
    }
    const total = discipline.performance;
    const getValueLabel = () => {
        if (discipline.evaluationModelType == EvaluationModelType.PERCENTAGE) {
            return formatAsPercentage(total);
        }
        if (discipline.evaluationModelType == EvaluationModelType.CONCEPTUAL) {
            return discipline.currentRating;
        }
        return total;
    };
    const getTileColor = () => {
        if (total > 0 && total < 60)
            return statusColor.color.error;
        if (total >= 85)
            return statusColor.color.success;
        return null;
    };
    return React.createElement(ValueTile, { "$color": getTileColor() }, getValueLabel());
};
const EvaluationBar = ({ label, discipline, evaluation }) => {
    const getBackgroundColor = () => {
        if (discipline.currentCount <= 0 || discipline.evaluations.length === 0) {
            return statusColor.background.error;
        }
        const total = discipline.currentWeight;
        if (total > 0 && total < 60)
            return statusColor.background.success;
        if (total >= 85)
            return statusColor.background.error;
        return null;
    };
    if (label) {
        return (React.createElement("div", null,
            React.createElement(Header, null,
                React.createElement(LabelTile, null, label),
                React.createElement(EvaluationBarValueTile, { discipline: discipline, evaluation: evaluation })),
            React.createElement(EvaluationProgressBar, { discipline: discipline, evaluation: evaluation })));
    }
    return (React.createElement(Container, { "$background": getBackgroundColor() },
        React.createElement(EvaluationProgressBar, { backgroundColor: "white", discipline: discipline, evaluation: evaluation }),
        React.createElement(EvaluationBarValueTile, { discipline: discipline, evaluation: evaluation })));
};
export default EvaluationBar;
