import React from 'react';
import { Card, StepProgress, TextAvatar } from '@campus/components';
import { ContextType, useAuthority } from '@campus/auth';
import { Header, HeaderContent, Content, Title, WeightContent, MessageWithoutPerformanceBox, MessageWithoutPerformance } from './styles';
import { EvaluationBar } from '../../components';
const DisciplineCard = ({ discipline, small = false }) => {
    const { currentContext } = useAuthority();
    const isTeacher = currentContext?.contextType === ContextType.TEACHER;
    return (React.createElement(Card, { fullHeight: true },
        React.createElement(Header, null,
            React.createElement(HeaderContent, null,
                React.createElement(TextAvatar, { color: discipline.color, text: discipline.name }),
                React.createElement(Title, null, discipline.name)),
            !small && (React.createElement(WeightContent, null, discipline.showPerformance ? (React.createElement(EvaluationBar, { label: "Meu Desempenho Campus", discipline: discipline })) : (React.createElement(MessageWithoutPerformanceBox, null,
                React.createElement(MessageWithoutPerformance, null, "Componente Curricular sem MDC")))))),
        React.createElement(Content, null, discipline.engagement.map((engagement, i) => (React.createElement(StepProgress, { key: `${i}${engagement.name}`, title: engagement.name, color: discipline.color, total: engagement.contentCount, current: engagement.currentCount, showStep: !isTeacher }))))));
};
export default DisciplineCard;
