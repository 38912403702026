import React from 'react';
import { Table } from '@campus/components';
import { EvaluationModelType } from '../../../models';
import { DisciplineColor, DisciplineText, DisciplineTitle, DisciplineTitleContainer, TableHead, TableSubHead, TablePercentage, TablePanel, TableHeadTitle, TableTd } from '../styles';
import { EvaluationBar } from '../../../components';
const PerformaceTable = ({ discipline, evaluations }) => {
    const disciplineFilter = discipline.filter((d) => {
        return d.showPerformance;
    });
    const renderEvaluationsByDiscipline = (studentDiscipline) => {
        return evaluations.map((e, i) => {
            const evaluationStudent = studentDiscipline.evaluations.find((stev) => stev.id === e.id);
            return (React.createElement(React.Fragment, { key: `${i}-${e.id}` },
                React.createElement(TableTd, null,
                    React.createElement(EvaluationBar, { discipline: studentDiscipline, evaluation: evaluationStudent })),
                React.createElement(TablePercentage, null, evaluationStudent?.evaluationTypeWeight == undefined
                    ? '-'
                    : `${evaluationStudent?.evaluationTypeWeight}${studentDiscipline.evaluationModelType === EvaluationModelType.PERCENTAGE ? '%' : ''}`)));
        });
    };
    return (React.createElement(TablePanel, null,
        React.createElement(Table, { "$hasScoreBorder": false, "$pdfView": true },
            React.createElement("thead", null,
                React.createElement("tr", null,
                    React.createElement(TableHeadTitle, { rowSpan: 2 },
                        "Meus ",
                        React.createElement("br", null),
                        "Componentes Curriculares"),
                    evaluations.map((e, i) => (React.createElement(TableHead, { key: `${i}-${e.title}`, "$color": e.color, colSpan: 2, width: 120 }, e.title))),
                    React.createElement(TableHead, { colSpan: 2, width: 120 }, "MDC")),
                React.createElement("tr", null,
                    evaluations.map((e, i) => (React.createElement(React.Fragment, { key: `${i}-${e.title}` },
                        React.createElement(TableSubHead, null, e.isPerformace ? 'Desempenho' : 'Engajamento'),
                        React.createElement(TableSubHead, null, "Peso")))),
                    React.createElement(TableSubHead, { width: 140 }, "Desempenho Final"))),
            React.createElement("tbody", null, disciplineFilter.map((d, i) => (React.createElement("tr", { key: `${i}-${d.id}` },
                React.createElement(DisciplineTitle, null,
                    React.createElement(DisciplineTitleContainer, null,
                        React.createElement(DisciplineColor, { "$color": d.color }),
                        React.createElement(DisciplineText, null, d.name))),
                renderEvaluationsByDiscipline(d),
                React.createElement(TableTd, null,
                    React.createElement(EvaluationBar, { discipline: d })))))))));
};
export default PerformaceTable;
