import React, { useState, useMemo, useEffect } from 'react';

import { Content, MyTools } from '@campus/components';
import {
  DashboardSection,
  useStudentId,
  useWindowDimensions,
  HeaderMobile,
  MainBanner
} from '@campus/commons';

import VestibularIcon from '@campus/components/assets/icons/vestibular-icon.svg';
import SasTestPlatformLogo from '@campus/components/assets/images/sas-test-platform-logo.png';

import { useNavigate } from 'react-router-dom';

import CheckAccess from 'models/CheckAccess';

import { checkAccessToSASTestPlatform } from 'services/sas';

import DisciplinesSection from './components/DisciplinesSection';
import TodaySection from './components/TodaySection';
import RevertColumn from './components/RevertColumn';

import { Root, Separator } from './styles';

const Home: React.FC = () => {
  const studentId = useStudentId();
  const { isMobile, breakpoint } = useWindowDimensions();

  const navigate = useNavigate();

  const [sasTestPlatform, setSasTestPlatform] = useState<CheckAccess | null>(
    null
  );

  const tools = useMemo(() => {
    const value = [
      {
        name: 'Campus Vestibular',
        click: () => {
          navigate('/vestibular');
        },
        icon: {
          src: VestibularIcon,
          alt: 'Campus vestibular',
          bgColor: '#F0F0F0'
        }
      }
    ];

    if (sasTestPlatform?.hasAccess) {
      value.push({
        name: 'Simulados da Editora SAS',
        click: () => {
          window.open(sasTestPlatform.platformURL, '_blank');
        },
        icon: {
          src: SasTestPlatformLogo,
          alt: 'Simulados da Editora SAS',
          bgColor: '#F0F0F0'
        }
      });
    }

    return value;
  }, [navigate, sasTestPlatform]);

  useEffect(() => {
    checkAccessToSASTestPlatform().then((result) => {
      if (result.data) {
        setSasTestPlatform(result.data);
      }
    });
  }, []);

  return (
    <div style={{ backgroundColor: '#F3F3F3' }}>
      <Content>
        <Root>
          {isMobile && (
            <HeaderMobile
              background="linear-gradient(313deg, #003C71 0%, #B7295A 100%)"
              title="Página Inicial"
              isExpandedHeader
            />
          )}
          <RevertColumn revert={isMobile}>
            {!isMobile ? <TodaySection /> : <></>}
            {isMobile ? <></> : <Separator />}
            <DashboardSection studentId={studentId} />
          </RevertColumn>
          <MainBanner />
          {isMobile ? <TodaySection /> : <></>}

          <MyTools tools={tools} marginTop={35} breakpoint={breakpoint} />

          <DisciplinesSection />
        </Root>
      </Content>
    </div>
  );
};

export default Home;
