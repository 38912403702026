import { Api } from '@campus/auth';
import { Response } from '@campus/commons';

import CheckAccess from 'models/CheckAccess';

export async function checkAccessToSASTestPlatform(): Promise<
  Response<CheckAccess>
> {
  try {
    const axiosInstance = Api.INSTANCE.getApi();

    const response = await axiosInstance.get('sas/test-platform/check-access');

    const data = CheckAccess.fromJson(response.data);

    return Response.fromData(data, response.status);
  } catch (error: unknown) {
    return Response.error(error);
  }
}
